import React from "react";
import Slider from "react-slick";

const sliderList = [
  {
    Title: 'Bringing tech-led business changes into focus',
    motto: 'Looking Glass 2024',
    Description: null,
    id: 1,
    img: '/components/Slider/slider1.png',
    link: '/',
  },
  {
    Title: 'Thoughtworks recognized as a Visionary',
    motto: 'Gartner® Magic Quadrant™',
    Description: null,
    id: 2,
    img: '/components/Slider/slider2.png',
    link: '/',
  },
];

export default function SliderHome() {
  const settings = {
    dots: true,
    fade: true,
    waitForAnimate: false,
    lazyLoad: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Slider className="slider-main" {...settings}>
      {sliderList.map(data => (
        <div key={data.id}  className="slider-body">
          <img className="slider-img" src={data.img} alt="" />
          <div className="slider-content">
            <h3 className="headline-font">{data.Title}</h3>
          </div>
        </div>
      ))}
    </Slider>
  );
}
