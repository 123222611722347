import React from 'react';
import { Container } from '@mui/material';
import { Text, ActionIcon, Group } from '@mantine/core';
import LogoSvg from '../svg/logo.svg';
import { IconBrandTwitter, IconBrandYoutube, IconBrandInstagram } from '@tabler/icons-react';
import { Link, useLocation } from 'react-router-dom';

const data = [
    {
        title: 'Links',
        links: [
            // { label: 'Join Discord', link: '#' },
            { label: 'Home', link: '' },
            { label: 'About', link: '/About' },
            { label: 'Contact', link: '/Contact' },
        ],
    },
];

function Footer() {
    const groups = data.map((group) => {
        const links = group.links.map((link, index) => (
            <Link
                key={index}
                className="footer-link"
                // component="a"
                to={link.link}
            // onClick={(event) => event.preventDefault()}
            >
                {link.label}
            </Link>
        ));

        return (
            <div className="footer-grid" key={group.title}>
                <Text className="footer-grid-title">{group.title}</Text>
                <div className="footer-grid-links">
                    {links}
                </div>
            </div>
        );
    });

    const location = useLocation();

    const footerClassNames = `footer-section ${location.pathname === '/Page' ? 'footer-section-page' : ''}`;

    return (
        <div className={footerClassNames}>
            <Container maxWidth="lg">
                <div className="footer-body">
                    <div className="footer-logo">
                        <img src="/logo.png" alt="Logo" />
                        <Text size="xs" color="dimmed" className="footer-motto">
                            Where Vision Meets Virtuality.
                        </Text>
                    </div>
                    <div className="footer-links-list">{groups}</div>
                </div>
            </Container>
            <div className="dm-footer">
                <div className="footer-copyrights">
                    <Text color="dimmed" size="sm">
                        © 2024 Nexum Solutions. All rights reserved.
                    </Text>
                </div>
            </div>
            {/* <Container maxWidth="lg">
                <div className="footer-copyrights">
                    <Text color="dimmed" size="sm">
                        © 2024 Nexum Solutions. All rights reserved.
                    </Text>

                    <Group gap={0} className="footer-socials" justify="flex-end" wrap="nowrap">
                        <ActionIcon size="lg" color="gray" variant="subtle">
                            <IconBrandTwitter style={{ width: '1rem', height: '1rem' }} stroke={1.5} />
                        </ActionIcon>
                        <ActionIcon size="lg" color="gray" variant="subtle">
                            <IconBrandYoutube style={{ width: '1rem', height: '1rem' }} stroke={1.5} />
                        </ActionIcon>
                        <ActionIcon size="lg" color="gray" variant="subtle">
                            <IconBrandInstagram style={{ width: '1rem', height: '1rem' }} stroke={1.5} />
                        </ActionIcon>
                    </Group>
                </div>
            </Container> */}
        </div>
    );
}

export default Footer;
