import React from 'react'
import { Title, Text, Image, Overlay } from '@mantine/core';
import { Container, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const About = () => {
    return (
        <div className='about-section'>
            <div className="about-slider">
                <Image height={200} src="/components/Slider/aboutslider.png" />
            </div>
            <Container maxWidth="lg">
                <div className="about-body">
                    <Title order={1}>About Us</Title>
                    <Title order={4}>
                        Welcome to Nexum Solutions, where we breathe life into digital innovation through the art of programming. With a relentless commitment to excellence, we embark on each project fueled by our passion for crafting exceptional software solutions.
                    </Title>
                    <Text className="text-second">
                        At Nexum Solutions, we understand the transformative power of code. From conception to execution, our team of dedicated programmers and software developers harnesses cutting-edge technologies to engineer robust, scalable, and intuitive software products. With every line of code, we strive to push the boundaries of possibility, delivering tailored solutions that meet the unique needs of our clients.
                        <br></br>
                        <br></br>
                        Our journey in the realm of software development is guided by a steadfast dedication to quality, innovation, and client satisfaction. We believe that technology should not only solve problems but also inspire progress. Therefore, we approach each project as an opportunity to create impactful solutions that drive digital transformation and empower businesses to thrive in the ever-evolving digital landscape.
                        <br></br>
                        <br></br>
                        Join us on our mission to revolutionize the world of software development. Whether you're a startup looking to make your mark or an established enterprise seeking to enhance your digital presence, Nexum Solutions is here to turn your vision into reality. Let's embark on this journey together and pave the way for a future where technology knows no bounds.
                    </Text>
                </div>
            </Container>
            <div className="banner-home">
                <Image height={120} src="/components/Slider/aboutsliderfooter.png" />
                <Overlay color="#000" opacity={0.1} zIndex={1} />
                <div className="contact-slider-content">
                    <Container maxWidth="lg">
                        <div className="banner-text">
                            <Text size="xl" ta="center" fw={700} className="contact-card-title">
                                Unlock Innovation. Empowering Your Digital Future. Contact Us to Start Your Journey.
                            </Text>
                            <Button
                                type="button"
                                component={Link}
                                to="/Contact"
                                variant="contained"
                            // startIcon={pagesaLoading && <CircularProgress size={20} color="inherit" />}
                            >
                                Contact Us
                            </Button>
                        </div>
                    </Container>
                </div>
                {/* <Overlay color="#000" opacity={0.35} /> */}
            </div>
        </div>
    )
}

export default About
