import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from './components/Home';
import Navmenu from './header/Navmenu';
import { Container } from '@mui/material';
import reducer from './components/store';
import { injectReducer } from './redux/store';
import Footer from './footer/Footer';
import Page from './components/Page';
import About from './components/About';
import Logos from './components/Logos';
import Contact from './components/Contact';
// import { useDispatch, useSelector } from 'react-redux';

injectReducer('Website', reducer)

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function Root() {
  return (
    <div className="site">
      <Router basename="/">
        <header>
          <Navmenu />
        </header>
        <div className="personal-body">
          <ScrollToTop />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/About" element={<About />} />
            <Route exact path="/Page" element={<Page />} />
            <Route exact path="/Contact" element={<Contact />} />
            <Route exact path="/logos" element={<Logos />} />
          </Routes>
        </div>
        <footer>
          <Footer />
        </footer>
      </Router>
    </div>
  );
}

export default Root;
