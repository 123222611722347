import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import LogoSvg from '../svg/logo.svg';
import { IconChevronDown } from '@tabler/icons-react';
import { Menu, Group, Center, Burger, Drawer } from '@mantine/core';
import { Link, NavLink } from 'react-router-dom';

const links = [
    { link: '/', label: 'Home' },
    { link: '/about', label: 'About' },
    // {
    //     link: '/',
    //     label: 'Services',
    //     links: [
    //         { link: '/Page', label: 'Web Development' },
    //         { link: '/Page', label: 'Mobile Development' },
    //         { link: '/Page', label: 'Maintenance & Support' },
    //         { link: '/Page', label: 'Evaluation & Design' },
    //     ],
    // },
    // { link: '/Page', label: 'Technologies' },
    // { link: '/News', label: 'News' },
    { link: '/Contact', label: 'Contact' },
];

const Navmenu = () => {
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const isScrolled = window.scrollY > 0;
            setScrolled(isScrolled);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const [opened, setOpened] = useState(false);

    const toggle = () => {
        setOpened(!opened);
    };



    const MobileNav = links.map((link) => (
        <NavLink
            key={link.label}
            to={link.link}
            className="nav-link"
            onClick={toggle}
        >
            {link.label}
        </NavLink>
    ));
    
    const items = links.map((link) => {
        const menuItems = link.links?.map((item) => (
            <Menu.Item className="nav-sublink" key={item.link}>
                <NavLink key={link.label} className="nav-link" to={item.link}>
                    {item.label}
                </NavLink>
            </Menu.Item>
        ));

        if (menuItems) {
            return (
                <Menu key={link.label} trigger="hover" transitionProps={{ exitDuration: 0 }} withinPortal>
                    <Menu.Target>
                        <a
                            href={link.link}
                            className="nav-link"
                            onClick={(event) => event.preventDefault()}
                        >
                            <Center>
                                <span className="nav-subtitle">{link.label}</span>
                                <IconChevronDown size="0.9rem" stroke={1.5} />
                            </Center>
                        </a>
                    </Menu.Target>
                    <Menu.Dropdown>{menuItems}</Menu.Dropdown>
                </Menu>
            );
        }

        return (
            <NavLink
                key={link.label}
                to={link.link}
                className="nav-link"
            // onClick={(event) => event.preventDefault()}
            >
                {link.label}
            </NavLink>
        );
    });


    return (
        <div className="navmenu-main">
            <div className={`personal-header${scrolled ? ' personal-header-scrolled' : ''}`}>
                <Container maxWidth="lg">
                    <div className="navmenu">
                        <Link to="/" className="logo">
                            {/* Logo */}
                            <img src="/logo.png" alt="Logo" />
                        </Link>
                        {/* <div className="support-btn"><Dropdown /></div> */}
                        <Group gap={5} visiblefrom="sm" className="group-desktop">
                            {items}
                        </Group>
                        <Burger opened={opened} onClick={toggle} size="sm" hiddenFrom="sm" className="burger-mobile" />
                        <Drawer
                            opened={opened}
                            onClose={toggle}
                            position="right"
                            size={300}
                            padding="xl"
                            className="drawer-mobile"
                        >
                            <div className="mobilemenu">
                                {MobileNav}
                            </div>
                        </Drawer>
                    </div>
                </Container>
            </div>
        </div>
    )
}

export default Navmenu
