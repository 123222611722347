// import { ButtonBase } from '@mui/material';
import React, { Fragment } from 'react'
import SliderHome from './Slider';
import { Container } from '@mui/material';
import { Button } from '@mui/material';
import { Image, AspectRatio, Overlay, Text } from '@mantine/core';
import GridsHome from './GridsHome';
import AboutHome from './AboutHome';
import NewsHome from './NewsHome';
import TechsHome from './TechsHome';
import ServicesHome from './ServicesHome';
import { Link } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
// import { useNavigate } from 'react-router-dom';

const Home = () => {
    // const navigate = useNavigate();
    // const dispatch = useDispatch();

    return (
        <div className='home-section'>
            <SliderHome />
            <Container maxWidth="lg">
                <div className="home-body">
                    <AboutHome />
                    {/* <GridsHome /> */}
                    <ServicesHome />
                    <TechsHome />
                </div>
            </Container>
            {/* <NewsHome /> */}
            <div className="banner-home">
                <Image height={120} src="/components/Slider/homebanner.png" />
                <Overlay color="#000" opacity={0.1} zIndex={1} />
                <div className="contact-slider-content">
                    <Container maxWidth="lg">
                        <div className="banner-text">
                            <Text size="xl" ta="center" fw={700} className="contact-card-title">
                                Unlock Innovation. Empowering Your Digital Future. Contact Us to Start Your Journey.
                            </Text>
                            <Button
                                type="button"
                                component={Link}
                                to="/Contact"
                                variant="contained"
                            // startIcon={pagesaLoading && <CircularProgress size={20} color="inherit" />}
                            >
                                Contact Us
                            </Button>
                        </div>
                    </Container>
                </div>
                {/* <Overlay color="#000" opacity={0.35} /> */}
            </div>
        </div>
    )
}

export default Home
