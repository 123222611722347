import React, { Fragment } from 'react'
import { Title, Text, UnstyledButton, Overlay, SimpleGrid, TextInput, Image, Textarea, Group } from '@mantine/core';
import { Container, Button } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { AiOutlineMail } from "react-icons/ai";
import { Link } from 'react-router-dom';

const categories = [
    {
        label: 'Customer Support',
        image:
            'https://images.unsplash.com/photo-1508780709619-79562169bc64?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=600&q=80',
    },
    {
        label: 'User Guides',
        image:
            'https://images.unsplash.com/photo-1516321318423-f06f85e504b3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=600&q=80',
    },
    {
        label: 'Questions',
        image:
            'https://images.unsplash.com/photo-1543286386-713bdd548da4?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=600&q=80',
    },
];


const Contact = () => {
    const items = categories.map((category) => (
        <UnstyledButton
            style={{ backgroundImage: `url(${category.image})` }}
            className="contact-card"
            key={category.label}
        >
            <Overlay color="#000" opacity={0.6} zIndex={1} />
            <Text size="xl" ta="center" fw={700} className="contact-card-title">
                {category.label}
            </Text>
        </UnstyledButton>
    ));

    const initialValues = {
        name: '',
        email: '',
        subject: '',
        message: ''
    };

    const onSubmit = (values, actions) => {
        console.log(values);
        actions.setSubmitting(false);
    };

    return (
        <Fragment>
            <Container maxWidth="lg">
                <div className="contact-body">
                    <div className="contact-form-main">
                        <div className="contact-form">
                            <div className="contact-form-bg">
                                <h1 className="bg-title">CONTACT</h1>
                                <Title className="contact-title" order={1}>Get in Touch with Us</Title>
                            </div>

                            <div className="contact-demo">
                                <a className="demo-box" href="mailto:info@nexumsolutions.io">
                                    <AiOutlineMail />
                                    Email Us
                                </a>
                                <a href="mailto:info@nexumsolutions.io">
                                    info@nexumsolutions.io
                                </a>
                            </div>

                            {/* <Formik
                                initialValues={initialValues}
                                onSubmit={onSubmit}
                            >
                                {() => (
                                    <Form>
                                        <SimpleGrid columns={{ base: 1, sm: 2 }} mt="xl">
                                            <Field
                                                as={TextInput}
                                                name="name"
                                                label="Name"
                                                placeholder="Your name"
                                                variant="filled"
                                            />
                                            <Field
                                                as={TextInput}
                                                name="email"
                                                label="Email"
                                                placeholder="Your email"
                                                variant="filled"
                                            />
                                        </SimpleGrid>

                                        <Field
                                            as={TextInput}
                                            name="subject"
                                            label="Subject"
                                            placeholder="Subject"
                                            mt="md"
                                            variant="filled"
                                        />
                                        <Field
                                            as={Textarea}
                                            name="message"
                                            label="Message"
                                            placeholder="Your message"
                                            mt="md"
                                            maxRows={10}
                                            minRows={5}
                                            autosize
                                            variant="filled"
                                        />

                                    </Form>
                                )}
                            </Formik> */}

                        </div>
                        <Group justify="center" mt="xl">
                            {/* <Button type="submit" size="md">
                                Send message
                            </Button> */}
                            {/* <Button
                                type="button"
                                variant="contained"
                            // startIcon={pagesaLoading && <CircularProgress size={20} color="inherit" />}
                            >
                                Send message
                            </Button> */}
                        </Group>
                    </div>
                    {/* <SimpleGrid cols={3} spacing="xl">{items}</SimpleGrid> */}
                </div>
            </Container>

            <div className="banner-home contact-banner">
                <Image height={120} src="/components/Slider/contactbanner.png" />
                <Overlay color="#000" opacity={0.1} zIndex={1} />
                <div className="contact-slider-content">
                    <Container maxWidth="lg">
                        <div className="banner-text">
                            <Text size="xl" ta="center" fw={700} className="contact-card-title">
                                Elevate Your Operations. Explore Our Services Today.
                            </Text>
                            <Button
                                type="button"
                                component={Link}
                                to="/"
                                variant="contained"
                            // startIcon={pagesaLoading && <CircularProgress size={20} color="inherit" />}
                            >
                                Ask a Service
                            </Button>
                        </div>
                    </Container>
                </div>
                {/* <Overlay color="#000" opacity={0.35} /> */}
            </div>
        </Fragment>
    )
}

export default Contact
