import React from 'react'
import { Image, Title, Text } from '@mantine/core';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

const AboutHome = () => {
    return (
        <div className='about-home-body'>
            <div className="about-home-text">
                <Title order={1}>About us</Title>
                <Text fz="md">
                    At Nexum Solutions, we are passionate about programming and technology. Since our establishment, we have been dedicated to delivering innovative software solutions that empower businesses and individuals alike.
                    <br></br>
                    <br></br>
                    Nexum Solutions's mission is to harness programming's power to solve challenges and drive digital transformation. We aim to provide cutting-edge solutions that streamline processes, enhance productivity, and propel clients towards success in the digital age.                
                </Text>
                <Button
                    type="button"
                    component={Link}
                    to="/About"
                    variant="contained"
                // startIcon={pagesaLoading && <CircularProgress size={20} color="inherit" />}
                >
                    Read more
                </Button>
            </div>

            <div className="about-home-image">
                <Image mx="auto" radius="md" src="/components/media/about-home.jpg" alt="Random image" />
            </div>
        </div>
    )
}

export default AboutHome
