import { createSlice } from '@reduxjs/toolkit';

const saveDataToLocalStorage = (key, data) => {
    localStorage.setItem(key, JSON.stringify(data));
};

const getDataFromLocalStorage = (key) => {
    const storedData = localStorage.getItem(key);
    return storedData ? JSON.parse(storedData) : undefined;
};

const dataSlice = createSlice({
    name: 'Website/data',
    initialState: {
        PaymentSelected: getDataFromLocalStorage('PaymentSelected') || [],
        PaymentResponse: getDataFromLocalStorage('PaymentResponse') || null,
        LlojiPagesesKesco: [],
        ManualetSelectedInsitucioni: null,
        LlojiPagesesManualet: [],
        Institucionet: [],
    },
    reducers: {
        SelectPayment: (state, action) => {
            state.PaymentSelected = action.payload;
            saveDataToLocalStorage('PaymentSelected', state.PaymentSelected);
        },
        setPaymentResponse: (state, action) => {
            state.PaymentResponse = action.payload;
            saveDataToLocalStorage('PaymentResponse', state.PaymentResponse);
        },
        // setLlojiPagesesKesco: (state, action) => {
        //     state.LlojiPagesesKesco = action.payload;
        // },
        setManualetSelectedInsitucioni: (state, action) => {
            state.ManualetSelectedInsitucioni = action.payload;
        },
        setLlojiPagesesManualet: (state, action) => {
            state.LlojiPagesesManualet = action.payload;
        },
        setInstitucionet: (state, action) => {
            state.Institucionet = action.payload;
        },
    },
});

export const {
    SelectPayment,
    setPaymentResponse,
    // setLlojiPagesesKesco,
    setLlojiPagesesManualet,
    setManualetSelectedInsitucioni,
    setInstitucionet
} = dataSlice.actions;

export default dataSlice.reducer;
