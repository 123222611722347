import React from 'react'
import { Title, Text, Image } from '@mantine/core';
import { Container } from '@mui/material';

const Page = () => {
    return (
        <div className="page-section">
            <div className="page-slider">
                <Image height={400} src="/components/Slider/3.png" />
            </div>
            <Container maxWidth="lg">
                <div className="page-body">
                    <Title order={1}>Coming Soon</Title>
                    <Image className='comingsoon-img' height={200} src="/components/media/comingsoon.png" />
                    {/* <Text className="text-second">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed pharetra consequat quam et facilisis. Maecenas sit amet consectetur est, auctor iaculis ipsum. Curabitur et aliquet arcu, eget egestas magna. Fusce in elit lorem. Donec at nulla nunc. Morbi tempor congue placerat. Curabitur vitae purus magna. Sed ut pellentesque massa, vitae ultrices risus. Donec ut tellus id leo faucibus luctus. Donec varius purus vitae lectus vulputate venenatis. Aliquam ultrices a enim non lobortis. Nullam id nisi consectetur, malesuada metus vel, ultricies nulla.

                        Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nulla ac aliquet nibh. Mauris hendrerit malesuada diam nec euismod. Vivamus dolor ex, volutpat id tincidunt nec, mollis molestie sem. Pellentesque pharetra dapibus ipsum quis commodo. Aenean mi est, volutpat sit amet nunc ac, accumsan rhoncus libero. Vestibulum at leo tempor, ultrices lorem vel, pharetra nulla. Etiam ligula lectus, placerat vel convallis non, gravida id ligula.                     
                        </Text> */}
                </div>
            </Container>
        </div>
    )
}

export default Page
