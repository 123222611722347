import React from 'react'
import { Image, Title, Text } from '@mantine/core';

const frontList = [
    {
        Title: 'React',
        id: 1,
        img: '/components/media/react.png',
        link: '/',
    },
    {
        Title: 'Typescript',
        id: 2,
        img: '/components/media/ts.svg',
        link: '/',
    },
    {
        Title: 'Angular',
        id: 3,
        img: '/components/media/angular.svg',
        link: '/',
    },
    {
        Title: 'Vue Js',
        id: 4,
        img: '/components/media/vue.svg',
        link: '/',
    },
    {
        Title: 'Next Js',
        id: 5,
        img: '/components/media/next.png',
        link: '/',
    },
    {
        Title: 'Blazor',
        id: 6,
        img: '/components/media/blazor.png',
        link: '/',
    },
    {
        Title: '.Net Maui',
        id: 7,
        img: '/components/media/maui.png',
        link: '/',
    },
    {
        Title: 'Javascript',
        id: 8,
        img: '/components/media/js.svg',
        link: '/',
    },
    {
        Title: 'Bootstrap',
        id: 9,
        img: '/components/media/bootstrap.svg',
        link: '/',
    },
    {
        Title: 'jQuery',
        id: 10,
        img: '/components/media/jquery.svg',
        link: '/',
    },
];

const backList = [
    {
        Title: 'Microsoft.NET',
        id: 1,
        img: '/components/media/dotnet.svg',
        link: '/',
    },
    {
        Title: 'ASP.NET Core',
        id: 2,
        img: '/components/media/core.svg',
        link: '/',
    },
    {
        Title: 'ASP.NET',
        id: 3,
        img: '/components/media/asp.png',
        link: '/',
    },
    {
        Title: '.Net Core',
        id: 4,
        img: '/components/media/netcore.svg',
        link: '/',
    },
    {
        Title: 'C#',
        id: 5,
        img: '/components/media/csharp.svg',
        link: '/',
    },
];

const mobileList = [
    {
        Title: 'React Native',
        id: 1,
        img: '/components/media/reactnative.png',
        link: '/',
    },
    {
        Title: '.Net Maui',
        id: 2,
        img: '/components/media/maui.png',
        link: '/',
    },
    {
        Title: 'jQuery Mobile',
        id: 3,
        img: '/components/media/jquerymobile.png',
        link: '/',
    },
];

const dataList = [
    {
        Title: 'MySQL',
        id: 1,
        img: '/components/media/mysql.svg',
        link: '/',
    },
    {
        Title: 'SQL Database',
        id: 2,
        img: '/components/media/sql.svg',
        link: '/',
    },
];

// Back end : blazor, Microsoft.NET , c#, ASP.NET, .Net Core, ASP.NET Core, Xamarin https://www.altoros.no/programvareutvikling/?utm_medium=cpc&utm_source=adwords_search&utm_campaign=dtbl_it_selskap_norway_no_acq&utm_content=158181188386&utm_term=it%20bedrifter%20norge&matchtype=e&device=c&gad_source=1

// Front end: React, Angular, Vue, Next, Bootstrap, Typescript, Jquery, Javascript

// Mobile : React Native, MyUi, Xamarin

// Data layer, postgrid, sql, MySql

// &&&&&&&&&&&&&&&&&&&&&&

// Remove rrjetet sociale ne footer

// Services : 
// 1. Custom Applications & Software Development
// 2. Application & Software Development teams
// 3. Mobile Development
// 4. Big Data Engineering
// 5. Maintance & Support
// 6. Application & Software Testing

const TechsHome = () => {
    return (
        <div className="techs-home">
            <div className="techs-home-header">
                <Title order={1}>Our company is based on these Technologies</Title>
                <Text className="text-second">
                    Our company is rooted in software development expertise, harnessing the latest tools and methodologies to deliver tailored solutions. With a commitment to excellence, we pave the way for seamless digital experiences and transformative business outcomes.  Through our relentless pursuit of excellence, we empower businesses to thrive in an ever-changing digital landscape, ensuring they remain ahead of the curve.
                </Text>
            </div>

            <Title order={3}>Front End</Title>
            <div className="techs-home-list">
                {frontList.map(data => (
                    <div key={data.id} className="techBox">
                        <Image mx="auto" radius="md" src={data.img} alt={data.Title} />
                        <Title order={5}>{data.Title}</Title>
                    </div>
                ))}
            </div>

            <Title order={3}>Back End</Title>
            <div className="techs-home-list">
                {backList.map(data => (
                    <div key={data.id} className="techBox">
                        <Image mx="auto" radius="md" src={data.img} alt={data.Title} />
                        <Title order={5}>{data.Title}</Title>
                    </div>
                ))}
            </div>

            <Title order={3}>Mobile Development</Title>
            <div className="techs-home-list">
                {mobileList.map(data => (
                    <div key={data.id} className="techBox">
                        <Image mx="auto" radius="md" src={data.img} alt={data.Title} />
                        <Title order={5}>{data.Title}</Title>
                    </div>
                ))}
            </div>

            <Title order={3}>Data Layer</Title>
            <div className="techs-home-list">
                {dataList.map(data => (
                    <div key={data.id} className="techBox">
                        <Image mx="auto" radius="md" src={data.img} alt={data.Title} />
                        <Title order={5}>{data.Title}</Title>
                    </div>

                ))}
            </div>
        </div>
    )
}

export default TechsHome
