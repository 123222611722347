import React from 'react'
import { Image, Title, Text } from '@mantine/core';
import { Card } from '@mui/material';
import { FaMedapps } from "react-icons/fa6";
import { BiCustomize } from "react-icons/bi";
import { CiMobile2 } from "react-icons/ci";
import { GoDatabase } from "react-icons/go";
import { BiSupport } from "react-icons/bi";
import { GrTestDesktop } from "react-icons/gr";

const dataList = [
    {
        Title: 'Custom Applications & Software Development',
        description: 'Elevate your business with our Custom Applications & Software Development service. Tailored solutions for enhanced efficiency, productivity, and user experience.',
        id: 1,
        img: 'app1',
        link: '/',
    },
    {
        Title: 'Application & Software Development teams',
        description: 'Application & software development teams drive digital innovation, blending coding, design, and project management skills to craft cutting-edge solutions.',
        id: 2,
        img: 'app2',
        link: '/',
    },
    {
        Title: 'Mobile Development',
        description: 'Elevate your mobile presence with our dynamic development service. Seamlessly blending coding prowess, design finesse, and project management expertise, we craft innovative solutions.',
        id: 3,
        img: 'mobile',
        link: '/',
    },
    {
        Title: 'Big Data Engineering',
        description: 'Maximize your data potential with our Big Data Engineering service. Our expert team architects scalable solutions, unlocking valuable insights to drive informed decision-making and propel your business forward.',
        id: 4,
        img: 'data',
        link: '/',
    },
    {
        Title: 'Maintance & Support',
        description: 'Optimize your digital assets with our Maintenance & Support service. Our dedicated team ensures smooth operation and swift issue resolution, keeping your systems reliable while you focus on your core objectives.',
        id: 5,
        img: 'support',
        link: '/',
    },
    {
        Title: 'Application & Software Testing',
        description: 'Ensure software excellence with our Testing service. Our meticulous team conducts comprehensive assessments, guaranteeing seamless functionality and user satisfaction.',
        id: 6,
        img: 'testing',
        link: '/',
    },
];

const ServicesHome = () => {
    const app1 = <BiCustomize />
    const app2 = <FaMedapps />
    const mobile = <CiMobile2 />
    const data = <GoDatabase/>
    const support = <BiSupport />
    const testing = <GrTestDesktop />

    const features = dataList.map((feature) => (
        <div key={feature.id} className='services-card'>
            {/* Image */}

            <div className="services-icon">
                {feature.img === 'app1' && app1}
                {feature.img === 'app2' && app2}
                {feature.img === 'mobile' && mobile}
                {feature.img === 'data' && data}
                {feature.img === 'support' && support}
                {feature.img === 'testing' && testing}
            </div>
            <Text fz="lg" fw={600} mt="md">
                {feature.Title}
            </Text>
            <Text fz="sm" c="dimmed" mt="sm">
                {feature.description}
            </Text>
        </div>
    ));

    return (
        <div className="services-home">
            <div className="services-home-header">
                <Title order={1}>Services</Title>
                <div className="services-list">
                    {features}
                </div>
            </div>

        </div>
    )
}

export default ServicesHome
